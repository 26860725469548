import { store } from '@/app/store';
import { selectToken } from '@/app/store/authentication/authentication.slice';
import axios, { AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from 'axios';

export class HttpApi {
	private axiosInstance: AxiosInstance | null = null;

	constructor() {
		this.setUp();
	}

	public static getInstance(): HttpApi {
		return httpApi;
	}

	public request(): AxiosInstance {
		if(!this.axiosInstance) {
			throw Error();
		}

		return this.axiosInstance;
	}

	private setUp(): void {
		if (this.axiosInstance) {
			return;
		}

		this.axiosInstance = axios.create({
			baseURL: import.meta.env.VITE_API,
		});

		this.axiosInstance.interceptors.request.use(this.interceptRequest);
		this.axiosInstance.interceptors.response.use(this.interceptResponse);
	}

	private interceptRequest(config: InternalAxiosRequestConfig): InternalAxiosRequestConfig {
		const token = selectToken(store.getState());

		if(token) {
			config.headers['Authorization'] = 'Bearer ' + token;
		}

		return config;
	}

	private interceptResponse(response: AxiosResponse): AxiosResponse {
		return response;
	}
}

const httpApi: HttpApi = new HttpApi();
