import { store } from '@/app/store';
import { selectToken } from '@/app/store/authentication/authentication.slice';

interface IEventSubscription {
  event: string;
  callback: ApiCallback<any>;
}

interface ISocketData {
  event: string;
  payload: unknown;
}

type ApiCallback<T> = (payload: T) => void;

export class WebSocketApi {
	private socket: WebSocket | null = null;
	private subscriptions: IEventSubscription[] = [];

	public static getInstance(): WebSocketApi {
		return socketApi;
	}

	public connect(): void {
		if (this.socket) {
			return;
		}

		this.socket = new WebSocket(import.meta.env.VITE_WEBSOCKET_API);

		this.socket.addEventListener('open', () => {
			const token = selectToken(store.getState());

			this.send('authenticate', {
				token,
			});
		});

		this.listen();
	}

	public close(): void {
		if(!this.socket) {
			return;
		}

		this.socket.close();
		this.socket = null;
	}

	public on<T>(event: string, callback: ApiCallback<T>): void {
		this.subscriptions.push({
			event,
			callback,
		});
	}

	public send<T>(event: string, payload?: T) {
		if (!this.socket) {
			return;
		}

		// const token = selectToken(store.getState());

		this.socket.send(
			JSON.stringify({
				// token,
				event,
				payload,
			})
		);
	} 

	private listen(): void {
		if (!this.socket) {
			return;
		}

		this.socket.addEventListener('message', (messageEvent: MessageEvent) => {
			const { event, payload }: ISocketData = JSON.parse(messageEvent.data);
			console.log(event, payload);

			this.subscriptions.forEach((subscription) => {
				if (subscription.event === event) {
					subscription.callback(payload);
				}
			});
		});
	}
}

const socketApi: WebSocketApi = new WebSocketApi();
