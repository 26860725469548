import { RootState } from '@/app/store';
import { logIn } from '@/app/store/authentication/authentication.api';
import { PlayerId } from '@/features/players/players.model';
import { createSelector, createSlice } from '@reduxjs/toolkit';

interface IAuthenticationState {
	token: string | null;
	playerId: PlayerId | null;
}

const initialState: IAuthenticationState = {
	token: null,
	playerId: null,
};

const authenticationSlice = createSlice({
	name: 'authentication',
	initialState,
	reducers: {
		logOut: () => initialState,
	},
	extraReducers: (builder) => {
		builder.addCase(logIn.fulfilled, (state, action) => {
			state.playerId = action.payload.playerId;
			state.token = action.payload.token;
		});
	},
});

export const { logOut } = authenticationSlice.actions;

const selectSelf = (state: RootState) => state.authentication;

export const selectToken = createSelector(
	selectSelf,
	(state) => state.token
);

export const selectPlayerId = createSelector(
	selectSelf,
	(state) => state.playerId
);

export default authenticationSlice.reducer;
