import { WebSocketApi } from '@/app/websocket';
import { DieId } from '@/features/dice/dice.model';
import { createAsyncThunk } from '@reduxjs/toolkit';

const api = WebSocketApi.getInstance();

export const rollDice = createAsyncThunk('dice/rollDice', async () => {
	api.send('rollDice');
});

export const toggleDie = createAsyncThunk(
	'dice/toggleDie',
	async (dieId: DieId) => {
		api.send('toggleDie', dieId);
	}
);
