import { GameId } from '@/app/store/game/game.slice';
import { WebSocketApi } from '@/app/websocket';
import styles from '@/routes/game/Game.module.scss';
import { ScoresComponent } from '@/features/scores/ScoresComponent';
import { Dice } from '@/features/dice/Dice';
import { fetchGame } from '@/app/store/game/game.api';
import { Component } from 'react';

export async function gameLoader(gameId: GameId): Promise<null> {
	await fetchGame(gameId);

	return null;
}

class Game extends Component {
	public componentDidMount() {
		const socketApi = WebSocketApi.getInstance();
		socketApi.connect();
	}

	public componentWillUnmount() {
		const socketApi = WebSocketApi.getInstance();
		socketApi.close();
	}

	public render() {
		return (
			<div className={styles.Game}>
				<ScoresComponent />
				<Dice />
			</div>
		);
	}
}

export default Game;
