import { HttpApi } from '@/app/api';
import { store } from '@/app/store';
import { GameId, setGame } from '@/app/store/game/game.slice';
import { WebSocketApi } from '@/app/websocket';
import { IDie } from '@/features/dice/dice.model';
import { setDice } from '@/features/dice/dice.slice';
import { IPlayer } from '@/features/players/players.model';
import { setPlayers } from '@/features/players/players.slice';
import { Scores } from '@/features/scores/scores.model';
import { setScores } from '@/features/scores/scores.slice';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { Patch } from 'immer';

export async function fetchGame(id: GameId): Promise<void> {
	const api = HttpApi.getInstance();
	const response = await api.request().get('/game/' + id);
	const data: {
    id: GameId;
    players: IPlayer[];
    dice: IDie[];
    scores: Scores[];
  } = response.data;

	const dispatch = store.dispatch;

	data.dice.forEach((die) => (die.highlight = false));

	dispatch(
		setGame({
			id: data.id,
		})
	);
	dispatch(setPlayers(data.players));
	dispatch(setDice(data.dice));
	dispatch(setScores(data.scores));
}

const api = WebSocketApi.getInstance();

export const resetGame = createAsyncThunk('game/resetGame', async () => {
	api.send('resetGame');
});

export const patchState = createAction<{ dice: Patch[]; scores: Patch[] }>('patchState');

api.on<{ dice: Patch[]; scores: Patch[] }>('response', statePatches => {
	store.dispatch(patchState(statePatches));
});
