import { RootState } from '@/app/store';
import { patchState } from '@/app/store/game/game.api';
import { rollDice } from '@/features/dice/dice.api';
import { IDie } from '@/features/dice/dice.model';
import { EScoreType } from '@/features/scores/scores.model';
import { selectFocusedScore } from '@/features/scores/scores.slice';
import {
	createSelector,
	createSlice,
	PayloadAction,
} from '@reduxjs/toolkit';
import { diffApply } from 'just-diff-apply';

export interface IDiceState {
  status: 'idle' | 'loading' | 'failed';
	items: IDie[];
}

const initialState: IDiceState = {
	status: 'idle',
	items: [],
};

export const diceSlice = createSlice({
	name: 'dice',
	initialState: initialState,
	reducers: {
		setDice: (state, action: PayloadAction<IDie[]>) => {
			state.items = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(rollDice.pending, (state) => {
				state.status = 'loading';
			})
			.addCase(rollDice.fulfilled, (state) => {
				state.status = 'idle';
			})
			.addCase(rollDice.rejected, (state) => {
				state.status = 'failed';
			})
			.addCase(patchState, (state, action) => {
				diffApply(state.items, action.payload.dice);
			});
	},
});

export const selectDice = (state: RootState) => state.dice.items;

export const selectDiceAndHighlights = createSelector(
	[selectDice, selectFocusedScore],
	(dice, score) => ({
		dice,
		highlights:
      !!score && score.type === EScoreType.regex ? score.potential.matchedDiceIds : [],
	})
);

export const { setDice } = diceSlice.actions;

export default diceSlice.reducer;
