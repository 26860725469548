import {
	createSlice,
	PayloadAction,
} from '@reduxjs/toolkit';

export type GameId = number;

export interface IGame {
	id: GameId | null;
}

const initialState: IGame = {
	id: null,
} as IGame;

export const gameSlice = createSlice({
	name: 'game',
	initialState,
	reducers: {
		setGame: (state, action: PayloadAction<IGame>) => {
			state.id = action.payload.id;
		},
	},
});
export const { setGame } = gameSlice.actions;

export default gameSlice.reducer;
