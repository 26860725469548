import { selectPlayers } from '@/features/players/players.slice';
import { assignScore } from '@/features/scores/scores.api';
import { EScoreType } from '@/features/scores/scores.model';
import { focusScore, selectFocusedScore, selectScores, unfocusScore } from '@/features/scores/scores.slice';
import styles from '@/features/scores/Scores.module.scss';
import { useAppDispatch, useAppSelector } from '@/app/hooks';

export function ScoresComponent() {
	const dispatch = useAppDispatch();
	const players = useAppSelector((state) => selectPlayers(state));
	const scores = useAppSelector((state) => selectScores(state));
	const focusedScore = useAppSelector((state) => selectFocusedScore(state));

	const columns = [];

	columns.push(
		scores
			.filter((score) => score.playerId === players[0].id)
			.map((score, index) => (
				<div className={styles.scores__field} key={'field-' + index}>
					{score.id}: {score.name}
				</div>
			))
	);

	players.forEach((player) => {
		columns.push(
			scores
				.filter((score) => score.playerId === player.id)
				.map((score) => {
					let potentialClass: string = 'scores__field--normal';
					let displayedValue = score.points;

					if (
						score.points === null &&
						score.type === EScoreType.regex &&
						score.potential.points !== null &&
            !score.assigned
					) {
						displayedValue = score.potential.points;

						if (score.potential.points > 0) {
							potentialClass = 'scores__field--match';
						}
						else {
							potentialClass = 'scores__field--no-match';
						}
					}

					if(
						focusedScore !== null &&
						focusedScore.type === EScoreType.regex &&
						focusedScore.potential.pointsIfAssigned.findIndex(x => x.scoreId === score.id) !== -1
					) {
						displayedValue = focusedScore.potential.pointsIfAssigned.find(x => x.scoreId === score.id)!.points;

						if(displayedValue !== score.points) {
							potentialClass = 'scores__field--affected';
						}
					}

					return (
						<div
							className={`${styles.scores__field} ${styles[potentialClass]}`}
							key={'score-' + score.id}
							onClick={() => dispatch(assignScore(score.id))}
							onMouseEnter={() => dispatch(focusScore(score))}
						>
							{displayedValue}
						</div>
					);
				})
		);
	});

	const html = columns.map((column, index) => (
		<div
			className={styles.scores__column}
			key={'column-' + index}
			onMouseLeave={() => dispatch(unfocusScore())}>
			<div className={styles.scores__field}>{index > 0 ? players[index-1].name : ''}</div>
			{column}
		</div>
	));

	return <div className={styles.scores}>{html}</div>;
}
