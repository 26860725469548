import { RootState } from '@/app/store';
import { patchState } from '@/app/store/game/game.api';
import { assignScore } from '@/features/scores/scores.api';
import { ScoreId, Scores } from '@/features/scores/scores.model';
import {
	createSelector,
	createSlice,
	PayloadAction,
} from '@reduxjs/toolkit';
import { diffApply } from 'just-diff-apply';

export interface IScoresState {
  status: 'idle' | 'loading' | 'failed';
	focus: ScoreId | null
	items: Scores[];
}

const initialState: IScoresState = {
	status: 'idle',
	focus: null,
	items: [],
};

export const scoresSlice = createSlice({
	name: 'scores',
	initialState: initialState,
	reducers: {
		setScores: (state, action: PayloadAction<Scores[]>) => {
			state.items = action.payload;
		},
		focusScore: (state, action: PayloadAction<Scores>) => {
			state.focus = action.payload.id;
		},
		unfocusScore: (state) => {
			state.focus = null;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(assignScore.pending, (state) => {
				state.status = 'loading';
			})
			.addCase(assignScore.fulfilled, (state) => {
				state.status = 'idle';
			})
			.addCase(assignScore.rejected, (state) => {
				state.status = 'failed';
			})
			.addCase(patchState, (state, action) => {
				diffApply(state.items, action.payload.scores);
			});
	},
});

export const { setScores, focusScore, unfocusScore } = scoresSlice.actions;

const selectSelf = (state: RootState) => state.scores;

export const selectScore = (state: RootState, id: ScoreId) => {
	return state.scores.items.find((score) => score.id === id);
};

export const selectScores = (state: RootState) =>
	state.scores.items;

export const selectScoreFocus = createSelector(
	selectSelf,
	(state) => state.focus
);

export const selectFocusedScore = createSelector(
	[selectScores, selectScoreFocus],
	(scores, scoreId) => scores.find((score) => score.id === scoreId) ?? null
);

export default scoresSlice.reducer;
