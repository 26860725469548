import { useAppDispatch } from '@/app/hooks';
import { logIn } from '@/app/store/authentication/authentication.api';
import { logOut } from '@/app/store/authentication/authentication.slice';
import { Link } from 'react-router-dom';

function Login() {
	const dispatch = useAppDispatch();

	return <div>
		<button onClick={() => dispatch(logIn(1))}>Log in (Player 1)</button>
		<button onClick={() => dispatch(logIn(2))}>Log in (Player 2)</button>
		<button onClick={() => dispatch(logOut())}>Log out</button>
		<Link to="/game/1">Go to game</Link>
	</div>;
}

export default Login;
