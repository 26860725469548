import { enablePatches } from 'immer';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from '@/app/store';
import '@/index.css';
import { RouterProvider } from 'react-router-dom';
import { router } from '@/router';

enablePatches();

ReactDOM.createRoot(document.getElementById('root')!).render(
	// <React.StrictMode> // TODO: Enable strict mode
	<Provider store={store}>
		<RouterProvider router={router} />
	</Provider>
	// </React.StrictMode>
);
