import { RootState } from '@/app/store';
import { rollDice } from '@/features/dice/dice.api';
import { IPlayer } from '@/features/players/players.model';
import {
	createEntityAdapter,
	createSlice,
	PayloadAction,
} from '@reduxjs/toolkit';

export interface IPlayersState {
  status: 'idle' | 'loading' | 'failed';
}

const initialState: IPlayersState = {
	status: 'idle',
};

const playersAdapter = createEntityAdapter<IPlayer>({
	sortComparer: (a, b) => a.order - b.order,
});

export const playersSlice = createSlice({
	name: 'dice',
	initialState: playersAdapter.getInitialState(initialState),
	reducers: {
		setPlayers: (state, action: PayloadAction<IPlayer[]>) => {
			playersAdapter.setAll(state, action.payload);
			// state.status = 'idle';
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(rollDice.pending, (state) => {
				state.status = 'loading';
			})
			.addCase(rollDice.fulfilled, (state) => {
				state.status = 'idle';
			})
			.addCase(rollDice.rejected, (state) => {
				state.status = 'failed';
			});
	},
});

const playersSelectors = playersAdapter.getSelectors<RootState>(
	(state) => state.players
);
export const selectPlayers = (state: RootState) =>
	playersSelectors.selectAll(state);

export const { setPlayers } = playersSlice.actions;

export default playersSlice.reducer;
