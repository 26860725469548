import { WebSocketApi } from '@/app/websocket';
import { ScoreId } from '@/features/scores/scores.model';
import { createAsyncThunk } from '@reduxjs/toolkit';

const api = WebSocketApi.getInstance();

export const assignScore = createAsyncThunk(
	'scores/assignScore',
	async (scoreId: ScoreId) => {
		api.send('assignScore', scoreId);
	}
);
