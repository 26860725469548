export enum ELocalStorageKey {
	state = 'state',
}

export class LocalStorageService {
	public static get(key: ELocalStorageKey): object {
		const value = localStorage.getItem(key);

		if(value === null) {
			return {};
		}

		return JSON.parse(value);
	}

	public static set(key: ELocalStorageKey, value: any): void {
		localStorage.setItem(key, JSON.stringify(value));
	}

	public static remove(key: ELocalStorageKey): void {
		localStorage.removeItem(key);
	}
}
