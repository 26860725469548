import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { rollDice, toggleDie } from '@/features/dice/dice.api';
import { selectDiceAndHighlights } from '@/features/dice/dice.slice';
import styles from '@/features/dice/Dice.module.scss';
import { resetGame } from '@/app/store/game/game.api';
import { Link } from 'react-router-dom';

export function Dice() { // TODO: Rename to DiceComponent
	const dispatch = useAppDispatch();
	const { dice, highlights } = useAppSelector((state) =>
		selectDiceAndHighlights(state)
	);

	const items = dice.map((die, index) => {
		const dieClass = highlights.includes(die.id) ? 'highlight' : die.state;
		return (
			<div
				className={`${styles.dice__die} ${styles['dice__die--' + dieClass]}`}
				key={index}
				onClick={() => dispatch(toggleDie(die.id))}
			>
				{die.value}
			</div>
		);
	});

	return (
		<div className={styles.dice}>
			<div className={styles.dice__cup}>{items}</div>
			<div className={styles.dice__controls}>
				<button onClick={() => dispatch(rollDice())}>Roll dice</button>
				<button onClick={() => dispatch(resetGame())}>Reset game</button>
				<Link to='/login'>Go to login</Link>
			</div>
		</div>
	);
}
