import { HttpApi } from '@/app/api';
import { ELocalStorageKey, LocalStorageService } from '@/app/local-storage.service';
import { PlayerId } from '@/features/players/players.model';
import { createAsyncThunk } from '@reduxjs/toolkit';

export async function getSession(playerId: PlayerId): Promise<{ playerId: PlayerId; token: string }> {
	const api = HttpApi.getInstance();
	const response = await api.request().post('/login', {
		playerId,
	});

	LocalStorageService.set(ELocalStorageKey.state, response.data);

	return response.data;
}

export const logIn = createAsyncThunk('authentication/logIn', async (playerId: PlayerId) => {
	return await getSession(playerId);
});
