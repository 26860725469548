import authenticationReducer from '@/app/store/authentication/authentication.slice';
import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import gameReducer from '@/app/store/game/game.slice';
import playersReducer from '@/features/players/players.slice';
import diceReducer from '@/features/dice/dice.slice';
import scoresReducer from '@/features/scores/scores.slice';

export const store = configureStore({
	reducer: {
		authentication: authenticationReducer,
		game: gameReducer,
		players: playersReducer,
		dice: diceReducer,
		scores: scoresReducer,
	},

});

store.subscribe(() => {
	// console.log(store.getState());
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
