import { DieId } from '@/features/dice/dice.model';
import { PlayerId } from '@/features/players/players.model';

export enum EScoreType {
  bonus = 'bonus',
  regex = 'regex',
  sum = 'sum',
}

export type ScoreId = number;
export interface IScore {
  id: ScoreId;
  playerId: PlayerId;
  name: string;
  points: number | null;
  group: ScoreGroupId;
}

export interface IAssignableScore extends IScore {
  assigned: boolean;
  potential: {
    matchedDiceIds: DieId[];
    points: number | null;
    pointsIfAssigned: { scoreId: ScoreId, points: number | null }[];
  };
}

export interface IBonusScore extends IScore {
  type: EScoreType.bonus;
}

export interface IRegexScore extends IAssignableScore {
  type: EScoreType.regex;
}

export interface ISumScore extends IScore {
  type: EScoreType.sum;
}

export type ScoreGroupId = number | null;

export type Scores =
  | IRegexScore
  | IBonusScore
  | ISumScore;

