import { Link } from 'react-router-dom';

function Home() {
	return <div>
		<div>Home</div>
		<Link to="/login">Go to login</Link>
	</div>;
}

export default Home;
