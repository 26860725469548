import Game, { gameLoader } from '@/routes/game/Game';
import Home from '@/routes/home/Home';
import Login from '@/routes/login/Login';
import { createBrowserRouter } from 'react-router-dom';

export const router = createBrowserRouter([
	{
		path: '/',
		element: <Home />,
	},
	{
		path: '/login',
		element: <Login />,
	},
	{
		path: '/game/:gameId',
		element: <Game />,
		loader: async ({ params }) => {
			if(params.gameId) {
				return await gameLoader(parseInt(params.gameId));
			}

			return null;
		},
	},
]);
